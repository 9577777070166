$card-height: 100%;
// Override bootstrap variables
$primary: #337ab7;
$secondary: #6b2424; //dark red
$success: #3f634c; //green

$sizes: (
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    33: 33%,
    50: 50%,
    60: 60%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto
    );

// Override Telerik variables
$accent: #fce0b6; //light tan
$button-pressed-border: null;

// For public/private toggle on home page
$switch-label-display: inline;
$switch-label-text-transform: capitalize;
