@import "./common.scss";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins/breakpoints";

@import "../node_modules/@progress/kendo-theme-bootstrap/scss/variables";
@import "../node_modules/@progress/kendo-theme-bootstrap/scss/mixins/index";
@import "../node_modules/@progress/kendo-theme-bootstrap/scss/common/index";
@import "../node_modules/@progress/kendo-theme-bootstrap/scss/forms/index";
@import "../node_modules/@progress/kendo-theme-bootstrap/scss/button/index";
@import "../node_modules/@progress/kendo-theme-bootstrap/scss/combobox/index";

html, body {
  //height: 100%;
  width: 100%;
  position: absolute;
  padding: 0;
}

body {
  //overflow-y: scroll;
}

.splitter-container {
  //height: 100%;
  border: none;

  .navbar-banner {
    background-color: #FFFFFF;
    height: 40px;

    .navbar-brand {
      padding: 0 15px;
      height: auto;
    }

    ul {
      list-style-type: none;
      text-align: right;
      margin: 0;

      li {
        margin-right: 20px;
        display: inline-block;

        a {
          padding-top: 3px;
        }
      }
    }
  }

  ol.breadcrumb {
    border-radius: 0;
  }
}

.k-resize-handle {
  z-index: 50; // Lower the default so it doesn't appear above popups (with a z-index of 100).
}

.form-control, input:not(.k-checkbox):not(.k-radio), textarea, .k-textbox, .k-textarea, .k-dropdown > .k-dropdown-wrap {
  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty, &.k-state-invalid {
    border-color: $danger;
  }

  &:focus, &.k-state-focus {
    border-color: $primary;
    box-shadow: 0 0 0 0.1rem rgba($primary, .3);

    &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty, &.k-state-invalid {
      box-shadow: 0 0 0 0.1rem rgba($danger, .3);
    }
  }
}

.k-datepicker .k-picker-wrap.k-state-focused,
.k-datepicker .k-picker-wrap.k-state-active,
.k-timepicker .k-picker-wrap.k-state-focused,
.k-timepicker .k-picker-wrap.k-state-active,
.k-datetimepicker .k-picker-wrap.k-state-focused,
.k-datetimepicker .k-picker-wrap.k-state-active {
  border-color: $primary;
  box-shadow: 0 0 0 0.1rem rgba($primary, .3);
}

.code-wrapper {
  .ace_editor {
    background-color: transparent;

    .ace_gutter {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
}


label > input.k-checkbox, label > input.k-radio {
  top: -1px;
}

.k-form {
  padding: 0;

  .k-textbox:disabled, .k-textbox[readonly], .k-textarea:disabled, .k-textarea[readonly] {
    background-color: #e9ecef;
  }

  .k-required,
  .k-required.k-field-info {
    color: $danger;
  }
  /*.k-form-field + .k-form-field {
    margin-top: 0;
  }*/
  .k-form-field {
    margin-top: 0;
  }

    .k-form-field input:not(.k-checkbox):not(.k-radio), .k-form-field textarea {
      width: 100%;
    }

    .k-form-field .input-group, .form-group .input-group {
      input.k-textbox {
        width: 1%;
        flex: 1 1 0%;
      }

      .input-group-append button .fad {
        margin: 0;
      }

      .form-control.k-dropdown {
        border: none;

        .k-dropdown-wrap {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .input-group-append .k-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-color: $combobox-border;
      }
    }

  label + .panel {
    margin-top: -0.3rem;
  }

  div.d-flex > div.alert {
    margin: 0;
  }

  .k-form-field > .k-dropdown {
    width: 100%;
  }

  label.k-form-field > span:first-child, .k-form-field > label {
    margin-bottom: 0.2rem;
  }

  .k-form-field > .validation-message, app-field-validation .validation-message {
    padding: .1rem .4rem;
    margin-top: .1rem;
    display: block;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }

  .k-form-field.k-panelbar .k-item {
    .k-link.k-header {
      padding: 0.4rem 1rem;

      app-grid-header {
        margin-bottom: 0;
      }
    }

    .k-header + kendo-grid {
      border-style: solid none none none;
      max-height: 16rem;
    }
  }

  .k-textbox:disabled, .k-textbox[readonly] {
    background-color: $input-disabled-bg;
  }


  & > .k-form-field:last-child,
  & > .row:last-child > .k-form-field,
  .k-content > .k-form-field:last-child,
  .k-content > .row:last-child > .k-form-field {
    margin-bottom: 0;
  }

  label > input[type="radio"],
  label > input[type="checkbox"] {
    display: inline-block;
  }

  .k-form-field label.k-checkbox-label {
    line-height: 1.5;
    vertical-align: baseline;

    &:before {
      top: 4px;
    }

    &:after {
      top: 5px;
    }
  }

  .k-radio-label + .text-info {
    line-height: 17px;
    vertical-align: text-top;
    display: inline-flex;
    align-items: flex-start;
    position: relative;
  }

  fieldset {
    margin: 0;
    border-color: #888;

    legend {
      font-size: 1.3em;
      color: #777;
    }
  }
}

.side-panel {
  margin-right: 2rem;
  white-space: nowrap;

  .k-header {
    padding: .5rem 1rem;
  }

  .side-links {
    & > a, & > span {
      display: block;
      margin: 1rem 0;
      padding-right: 1rem;
      position: relative;
    }

      & > a.active {
        font-weight: bold;

        &:before {
          font-family: "Font Awesome 5 Duotone";
          content: "\f054";
          position: absolute;
          right: 0;
          color: var(--fa-primary-color,inherit);
          opacity: var(--fa-primary-opacity,1);
        }

        &:after {
          font-family: "Font Awesome 5 Duotone";
          content: "\10f054";
          position: absolute;
          right: 0;
          /*color: var(--fa-secondary-color,inherit);
        opacity: var(--fa-secondary-opacity,.4);*/
          color: var(--fa-primary-color,inherit);
          opacity: var(--fa-primary-opacity,1);
        }
      }
  }
}


/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

blockquote {
  font-size: 100%;
}

.fad {
  color: $primary;
}

.text-success .fad {
  color: $success !important;
}

.text-info .fad {
  color: $info !important;
}

.text-warning .fad {
  color: $warning !important;
}

.text-danger .fad {
  color: $danger !important;
}

.k-treeview {
  line-height: 1.4;

  .k-top, .k-mid, .k-bot {
    padding-top: 0;
    padding-bottom: 0;
  }

  .fad {
    font-size: .9rem;
    margin-right: .3rem;
    color: #888;

    &.state-current, &.state-path {
      color: $primary;
    }
  }

  .k-in {
    padding: 0 0.2rem;

    &:hover {
      border-color: #f2f2f2;
      background-color: #f2f2f2;
    }

    &.k-state-selected {
      border-color: #f2f2f2;
      background-color: rgba($primary, 0.1);
      color: inherit;
    }

      &.k-state-selected:hover {
        border-color: #f2f2f2;
        background-color: rgba($primary, 0.2);
      }

    &.k-state-focused {
      box-shadow: 0 0 0 1px #C0C0C0;

      &.k-state-selected {
        box-shadow: 0 0 0 1px rgba($primary, 0.4);
      }
    }
  }

  a {
    text-decoration: none;
    color: #212529;
    outline: none;
  }
}

kendo-grid.k-grid {
  &[ng-reflect-loading=true] .k-grid-content {
    min-height: 150px;
  }

  &.table-width-650 table {
    min-width: 650px;
  }

  &.table-width-1000 table {
    min-width: 1000px;
  }

  .fad {
    color: #999999;
  }

  .k-grid-header .k-grid-filter, .k-grid-header .k-header-column-menu {
    height: 1em;
    width: calc(2px + 1.3em);
  }

  .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm,
  .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm {
    margin-left: .25rem;
  }

  .k-grid-header .k-header > .k-link {
    padding-right: 0;
  }

  th, td {
    padding: 0.2rem 0.75rem;
  }

  td {
    height: 40px;
  }

    td.col-rownumber {
      color: $gray-500;
    }

    td.col-date {
      span {
        white-space: nowrap;
      }
    }

    th.icon-column, td.icon-column {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }

  a {
    color: $secondary;

    &:hover {
      text-decoration: underline;
    }
  }

  tr.inactive {
    td {
      background-color: #f7dcdc;
    }

    &.k-state-selected td {
      background-color: #f7b760;
    }
    /*    a {
      color: darken(#F44E4E, 15);
    }
*/
  }

  tr.deleted {
    td {
      background-color: #fab1b1;
    }

    &.k-state-selected td {
      background-color: #f7ab43;
    }
    /*    a {
      color: darken(#F44E4E, 15);
    }
*/
  }

  &.checkbox-grid tr td {
    cursor: default;
  }

  tr td input.grid-editor {
    padding: 0 0.5rem;
    height: auto;
    width: 100%;
  }
}

app-grid-header {
  display: block;
  margin-bottom: 0.5rem;

  span.grid-filter {
    float: right;
  }

  span.grid-selected {
    margin-left: 1.3rem;
  }
}

div.k-dialog {
  max-height: 90%;

  .k-dialog-buttongroup button {
    min-width: 70px;
  }
}


button.k-button + button.k-button {
  margin-left: .75rem;
}

kendo-dropdownlist.k-dropdown {
  .missing-item {
    font-style: italic;
    color: $gray-500;
  }
}

button.k-button.k-outline, kendo-dropdownbutton button.k-button {
  border-color: #CCC;
  color: #333;
  background-color: transparent;
  //margin-left: 2px;

  .fad {
    color: $primary;
    margin-right: 4px;
    font-size: .9rem;
    min-width: 15px;
  }

  &:hover {
    background-color: #F6F6F6;
    color: #000000;
    border-color: $primary;

    &.btn-invalid {
      border-color: $warning;
    }

    .fad {
      color: $primary;
    }
  }

  &.k-primary:active, &.k-primary:hover {
    .fad {
      color: $white;
    }
  }
}

button.k-button.k-outline {
  &.btn-invalid .fad {
    color: $warning;
  }

  &.btn-pristine .fad {
    color: $secondary;
  }

  &.k-state-disabled .fad {
    color: #BBB;
  }

  .fad.fa-exclamation {
    color: $warning !important;
  }

  .fad.fa-times {
    color: $danger !important;
  }

  .fad.fa-check-circle {
    color: $success !important;
  }
}

kendo-toolbar.k-toolbar {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.6rem;

  kendo-toolbar-renderer .k-separator {
    margin-left: 8px;
    margin-right: 8px;
    color: #CCC;
  }

  kendo-dropdownbutton {
    margin-right: 3px;
  }
}

kendo-toolbar.header-right {
  justify-content: flex-end;
}

.toolbar-wrapper {
  margin-bottom: 0.6rem;

  kendo-toolbar.k-toolbar {
    margin-bottom: 0;
  }
}

.k-notification-group {
  z-index: 15000;
}

ul.fancytree-container {
  border: 0;

  .glyphicon {
    color: #777777;
  }

  .fancytree-active .glyphicon {
    color: $primary;
  }
}

.k-popup {
  padding: 0.2rem .5rem;

  &.tooltip {
    background-color: #F0F7F0;
  }

  ul {
    margin-bottom: 0;
  }
}

.popup-trigger {
  margin-left: 4px;
  cursor: pointer;
}

kendo-splitter-pane {
  margin-bottom: 4px;

  .layout-content {
    padding: 1rem;
    //height: 100%;

    .vexpand-wrapper {
      //height: 100%;
      display: flex;
      flex-direction: column;

      .vexpand-resize {
        height: 4em; // This is the minimum height for the grid.
        flex-grow: 1; // Allow the grid to expand to fill the container.
        kendo-grid {
          max-height: 100%;
        }
      }
    }
  }
}

kendo-splitter-bar {
  background-color: white;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}


.page-content {
  .btn-toolbar {
    margin-bottom: 20px;

    &.line {
      padding-bottom: 10px;
      border-bottom: 1px solid #EEEEEE;
    }

    .btn-group button span.caret {
      margin-left: 4px;
    }
  }

  .nav-tabs .btn-toolbar {
    margin-bottom: 0;
  }

  .connection-wrapper {
    .connection-list {
      width: 90%;
    }
  }
}

h3.pageTitle {
  margin: 0 0 20px 0;

  a span.glyphicon {
    font-size: 70%;
    margin-left: 10px;
  }
}

.has-error .input-group-btn button, .error .input-group-btn button {
  border-color: #b94a48;
}

label.has-error {
  color: $danger;
}

.validation-summary-errors ul {
  list-style-type: none;
  color: $danger;
  padding-left: 0;
}
/*
  This is to correct an issue with long values in the drop-down that increase
  the width of the drop-down to be wider than the container.
*/
.bootstrap-select {
  display: table;
  table-layout: fixed;
}

ace {
  &[disabled] .ace_content {
    background-color: #f0f0f0;
  }
}

.k-dropdown .k-dropdown-wrap {
  background-color: #FFF;

  &:hover {
    background-color: #F8F8F8;
  }
}

.checklist label {
  font-weight: normal;
  color: inherit;
  display: block;
  margin: 0 0 0 10px;
}

.form-group > label:first-child {
  margin-bottom: 0.2rem;
}

.form-group label input + span, label > input + span {
  position: relative;
  top: 1px;
  margin-left: .4em;
}

.form-group label.checkbox-inline input[type='checkbox'] {
  top: -1px;
}

ul.checkbox-list {
  overflow: auto;
  max-height: 200px;
  border: 1px solid #CCCCCC;

  label {
    margin-left: 35px;
    text-indent: -22px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.2;
  }

  .field-validation-error {
    display: block;
    margin-left: 27px;
  }
}

.panel ul.checkbox-list {
  border: none;
  margin-bottom: 0;
}

.btn-toolbar {
  .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    opacity: 0.45;
  }
}

.glyphicon.disabled {
  opacity: 0.45;
}

.tooltip * {
  /*  &.in {
    opacity: 1;
  }
*/
  .tooltip-inner {
    background-color: #FFF;
    color: #000;
    border: 1px solid $primary;
  }

  &.top .tooltip-arrow {
    border-top-color: $primary;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: $primary;
  }

  &.left .tooltip-arrow {
    border-left-color: $primary;
  }

  &.right .tooltip-arrow {
    border-right-color: $primary;
  }
}

.btn-toolbar .btn-separator {
  margin: 0 0 0 15px;
  display: inline-block;
}

.tv-confirmation {
  min-width: 130px;

  .popover-title {
    background-color: #FFF;
    border-bottom: 0;
    padding: 8px 14px 0 14px;
    text-align: center;
  }

  .confirmation-buttons {
    text-align: center;

    a {
      margin-right: 8px;
      float: none;
    }

      a:last-child {
        margin-right: 0;
      }
  }
}

.eula-wrapper {
  .eula-content {
    max-height: 300px;
    overflow: auto;
    border: 1px solid #ddd;
    padding: 0.5rem 0.75rem;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }
  }

  .acceptance {
    margin-top: 10px;
  }
}

//-------------------------------------------------------------------
// Custom page styles

#loginForm {
  margin: 0 auto;
  width: 300px;

  img.logo {
    display: block;
    margin: 60px auto;
  }

  .validation-summary-errors ul {
    list-style-type: none;
    margin: 30px 0 0 0;
    padding: 0;

    li {
      text-align: center;
      font-size: 120%;
    }
  }
}

.card {
  .card-header {
    padding: 0.5rem 1rem;
  }

  .card-body {
    padding: 1rem;
  }
}

.k-list {
  .divider {
    margin: 4px 0;
    height: 1px;
    overflow: hidden;
    background-color: #E5E5E5;
    display: block;
  }
}

.k-popup.k-list-container {
  padding: 0;

  kendo-list > .k-outer-group-header {
    display: none;
  }

  .k-list > .k-outer-group-header {
    line-height: 1px;
    height: 1px;
    font-size: 0;
    color: #FFF;
    padding: 0;
  }
}


table#result-list tbody td {
  word-break: break-word;
}

.input-group span.filter-option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bootstrap-select .dropdown-toggle span.filter-option {
  width: 99%;
}

.disable-click {
  pointer-events: none;
}

.alert-button {
  display: none;
  padding-top: 7px;
  padding-bottom: 8px;
  margin: 0 0 0 20px;
  vertical-align: middle;
}


.k-filter-menu .k-filter-menu-container {
  padding: 0;

  .k-action-buttons {
    margin: 0;

    button {
      padding: .25rem 1rem;
    }
  }

  .description {
    margin: -.2rem -.5rem 0;
    padding: .2rem .5rem;
    border-bottom: 1px solid #DDD;
    font-size: 90%;
    background-color: #F7F7F7;
  }

  app-multi-check-list {
    ul.k-list {
      margin: -.2rem -.5rem 0;

      li {
        padding: .2rem 1rem;

        label {
          margin-bottom: 0;
        }
      }
    }
  }
}

.card-a {
  color: black;
  font-style: normal;
  text-decoration: none;
}

  .card-a:hover {
    text-decoration: none;
  }

.crudTitle {
    margin-top: 0.4rem;
}

.crud-a {
  color: $secondary;
  font-weight: 700;
}

.report-radio {
  margin: 0 0 -1em;
  padding: 0;
}

  .report-radio li {
    list-style: none;
    padding-bottom: 1em;
  }

.k-textbox {
  width: 300px;
}

.k-button-clear {
  width: 28px;
}

.k-textbox .send-button {
  color: #ff6358;
  width: 54px;
}

  .k-textbox .send-button:hover {
    color: #eb5b51;
  }

.k-input {
  color: black;
}

.report-holder {
  margin-top: 4px;
}


.toolbar-flex-container {
  display: flex;
  flex-wrap: wrap;

  .toolbar-flex-item {
    flex: 0 0 0;

    button {
      background-color: white;
      color: black;
      border-color: #ced4da;
      font-weight: 700;

      span.fad {
        margin-right: 7px;
        margin-top: 2px;
      }
    }
  }
}


.facet-flex-container {
  //display: flex;
  //flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;

  .facet-flex-item {
    //flex: 0 0 auto;

    button {
      border-color: #ced4da;
      font-weight: 700;
    }

    kendo-dropdownlist.k-dropdown, kendo-textbox.k-textbox {
      width: 100%;
    }
  }
}


.flex-container {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 1rem;
}

.flex-item {
  flex: 0 0 200px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-color: white;
}

.flex-item-tag {
  margin-right: 15px;
  margin-bottom: 10px;
  border-color: white;
}

div.centered {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.errormessage p {
  font-size: 2em;
  text-align: center;
  color: #3e0101;
}
// These are the tag filter badges, unselected and selected

.btn-info {
  background-color: #5bc0de;
  border-color: #46b8da;
  color: white;
}

  .btn-info:hover {
    background-color: #46b8da;
    border-color: #46b8da;
    color: white;
  }

.btn-primary {
  background-color: #286090 !important;
  border-color: #204d74;
  color: white;
}

  .btn-primary:focus {
    background-color: #286090;
    border-color: #204d74;
    color: white;
  }

  .btn-primary:hover {
    background-color: #46b8da !important;
    border-color: #46b8da;
    color: white;
  }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
/*.btn-primary.selected {
  background-color: #337ab7;
  border-color: #2e6da4;
  color: white;
}
*/
// This is the pills on the home page cards

.badge {
  font-size: 90%;
  font-weight: 500;
  padding: 0.4em 0.6em;
}

.badge-primary {
  background: #337ab7 !important;
  color: white;
  margin-right: 0.2rem;
}
// From index.html

html, body {
  min-height: 100%;
  font-size: 80%;
  line-height: 1.2;
  overflow-x: hidden;
}

.tagid {
  font-size: 70%;
}

.page-container {
  //height: 100%;
  //overflow-y: auto;
  overflow-x: hidden;
}

.main-container {
  //height: 100%;
}

footer {
  width: 100%;
  //height: 80px;
  background: #eee;
  padding-top: 7px;
  font-size: 14px;
}

.footer_image {
  margin-top: 10px;
}

footer a {
  color: darkblue;
}

.container {
  padding: 0px !important;
}

.container-width-fixed {
  min-width: 1140px !important;
}

.middle-container-1 {
  padding-top: 10px;
  margin: 0 1rem;
  //margin-bottom: 10px;
}

.img-background-container {
  background: url('/images/top_container_bg.jpg');
  background-size: cover;
  color: white;
  font-family: 'Arial Bold', 'Arial';
  font-style: normal;
  min-height: 130px;
  background-position-y: 50%;
}

@media screen and (min-width: 1300px) {
  .container {
    width: 1280px !important;
    max-width: 1280px !important;
  }
}

.banner-top-title {
  display: block;
  font-size: 32px;
  margin-top: 24px;
  font-weight: 700;
}

.banner-low-title {
  font-size: 20px;
  font-style: italic;
  position: relative;
  top: -5px;
}

div.fade {
  position: relative;
  overflow: hidden;
}

  div.fade:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
    width: 100%;
    height: 50px;
  }

.card .card-body {
  padding: 0rem 0rem 0rem 0rem;
}

.card-body {
  margin-left: 0.5rem;
}


.card-title {
  margin-bottom: 0rem;
}

.card-img-top {
  height: 110px;
  width: 110px;
  object-fit: cover;
  margin-bottom: 10px;
}

.card-fav-star {
  margin-bottom: 5px;
}

.screen-top-notification {
  background-color: #ffffd4;
  color: black;
  font-size: large;
  font-weight: 700;
  text-align: center;
}

.facets {
  margin-bottom: 27px;

  kendo-textbox, kendo-dropdownlist, button {
    min-width: 100%;
    width: 100%;
    vertical-align: bottom;
  }
}

.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  background-color: white;
  border-color: #ced4da;
}

  .k-dropdown .k-dropdown-wrap:hover, .k-dropdowntree .k-dropdown-wrap:hover, .k-dropdown .k-dropdown-wrap.k-state-hover, .k-dropdowntree .k-dropdown-wrap.k-state-hover {
    background-color: white;
    border-color: #ced4da;
  }

  .k-dropdown .k-dropdown-wrap.k-state-focused, .k-dropdowntree .k-dropdown-wrap.k-state-focused {
    background-color: white;
    border-color: #ced4da;
  }
/*  button {
  margin-top: 19px;
  }
*/

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.border-gray {
  border-color: lightgray;
}

.tooltipStyle * {
  background: #eeeeee;
  color: black;
}

.k-tooltip {
  max-width: 500px;
  /* or */
  /*white-space: normal;*/
}

.overflow-hidden {
  overflow: hidden;
}

//

.card-private {
  padding: 10px;
  flex: 0 0 401px;
  background-color: #ffffe7 !important;
  height: 238px;

  .private-margin {
    margin-bottom: 1rem;
  }

  .private-overflow {
    overflow: hidden;
    position: relative;
  }

  .private-size {
    //width: 187px;
    height: 4rem;
  }
}

.card-private-expand {
  padding: 10px;
  flex: 0 0 401px;
  background-color: #ffffe7 !important;
  height: auto;

  .private-margin {
    margin-bottom: 1rem;
  }

  .private-overflow {
    overflow: visible;
    position: relative;
  }

  .private-size {
    //width: 187px;
    height: auto;
  }
}

.card-public {
  padding: 10px;
  flex: 0 0 401px;
  height: 170px;

  .public-margin {
    margin-bottom: 1rem;
  }

  .public-overflow {
    overflow: hidden;
    position: relative;
  }

  .public-size {
    //width: 187px; 
    height: 5rem;
  }

  img {
    margin-bottom: 6px;
  }
}

.card-public-expand {
  padding: 10px;
  flex: 0 0 401px;
  height: auto;

  .public-margin {
    margin-bottom: 1rem;
  }

  .public-overflow {
    overflow: hidden;
    position: relative;
  }

  .public-size {
    //width: 187px;
    height: auto;
  }
}

//

.k-icon {
  color: $gray-500;
}

.content-container {
  //overflow-y: hidden;
}

.full-width {
  width: 100%;
}

.loading {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #808080;
  font-size: 2.5em;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.1);
}

.help-button {
    margin-top: 15px;
}

// SAS reports
sas-report {
  width: 100%;
  height: 700px;
  //border: 1px solid lightgray;
}

// Tableau reports
.container-wider {
  width: 1282px !important;
  max-width: 1282px !important;
}
.container-border {
  //border: 1px solid lightgray;
}
.container-scroll-x {
    overflow-x: auto;
}

.button-shift-left {
    margin-left: -1px;
}

//
div.extra-space-before {
  margin-top: 10px;
}

